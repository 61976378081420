import React from "react";
// import Header from "../../../../components/header/header1/index";
import Header from "../../../../components/header/Header";
import Content from "../../../../components/main/content/DynamicContent";
import Sidebar from "../../../../components/main/sidebar/Sidebar";
import Footer from "../../../../components/footer/Footer";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

const ArticlePage = (props) => {
  const { data } = props;
  const { pageContext } = props;
  const { postSearchData } = pageContext;
  const { allPosts, options } = postSearchData;
  let temp = data?.cms?.recent_story;
  let featured_post = temp.filter(checkFetured);
  function checkFetured(element) {
    return element.is_feature_post;
  }
  return (
    <div className="layout">
      <Helmet>
        <meta name="commission-factory-verification" content="9a2a0128730b4b2a88a55b488e9873b8" />
        <meta name="ir-site-verification-token" value="-1113842092" />
        <meta name="google-site-verification" content="y5lV9QGSNfjz1IRHuIZcYKOT6mVeiKkTeVY9zU73eAM" />
        <meta name="d103bf43fb93b7f" content="c0b9cd4d499846b447bed15f2e787549" />

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-FS55TRMLV6"></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-FS55TRMLV6');`}
        </script>
      </Helmet>
      <Header category={data?.cms?.categories} allPosts={allPosts} engine={options} domain={data?.cms?.domain} pages={data?.cms?.pages} />
      <div className="container is-max-widescreen">
        <div className="columns m-0">
          <Content story={data?.cms?.page} domain={data?.cms?.domain} />
          <Sidebar recent_story={featured_post} domain={data?.cms?.domain} />
        </div>
      </div>
      <Footer pages={data?.cms?.pages} domain={data?.cms?.domain} email={"editor@luxuryhotels.guide"} add="Address: 2140 S Dupont Highway camden, Kent DE 19934" />
    </div>
  );
};
export default ArticlePage;

export const query = graphql`
  query pages($slug: ID!) {
    cms {
      domain: CMS_Domain(id: "13") {
        id
        logo
        domain_url
        name
        description
        favicon
      }
      categories: CMS_CategoryByDomain(domain_id: "13") {
        name
        slug
      }
      recent_story: CMS_DomainWiseStories(domain_id: "13") {
        image
        slug
        title
        is_feature_post
        categories {
          name
        }
      }
      page: CMS_Page(id: $slug) {
        content
        slug
        title
      }
      pages: Published_Pages(domain_id: "1") {
        slug
        title
      }
    }
  }
`;
